export const GOSSIP_PEER_NUMBER = 3;
export const chooseRandom = (arr, num = 4) => {
  if (arr.length <= num) {
    return arr;
  }
  const res = [];
  for (let i = 0; i < num; ) {
    const random = Math.floor(Math.random() * arr.length);
    if (res.indexOf(arr[random]) !== -1) {
      continue;
    }
    res.push(arr[random]);
    i++;
  }
  return res;
};
