export const saveSnapshotToLocal = (timeStamp, snapshot) => {
  const pre = localStorage.getItem(timeStamp);
  if (pre === null) {
    const value = JSON.stringify([snapshot]);
    localStorage.setItem(timeStamp, value);
    console.log("save snapshots to localStorage", value);
  } else {
    const preSnapshots = JSON.parse(pre);
    const value = JSON.stringify([...preSnapshots, snapshot]);
    localStorage.setItem(timeStamp, value);
    console.log("save snapshots to localStorage", value);
  }
};
