import "./App.css";
import { useCallback, useEffect, useRef, useState } from "react";
import Post from "./components/post/index";
import Form from "./components/form/index";
import io from "socket.io-client";
import { chooseRandom, GOSSIP_PEER_NUMBER } from "./lib/gossipHelper";
import { v1 as uuid } from "uuid";

import { Decodeuint8arr } from "./lib/messageHelper";
import { saveSnapshotToLocal } from "./lib/snapshotHelper";
const BULLY_TIMEOUT = 20000;
const Peer = window.SimplePeer;

function App() {
  const [serverId, setServerId] = useState("");

  const serverIdRef = useRef("");

  const [peers, setPeers] = useState([]);
  const [posts, setPosts] = useState([]);

  const [isConnect, setIsConnect] = useState(false);
  const socketRef = useRef(null);
  const peersRef = useRef([]);
  const leaderRef = useRef("");

  const bullyElectionCountRef = useRef(0);

  /**
   * this set will store all gossip message id to avoid duplicate gassip
   */
  const gossipPostSetRef = useRef(new Set());

  /**
   *  postsRef is a backup of posts, it will used in getAllPreviousPosts
   *  keep updating postsRef when you update posts
   */
  const postsRef = useRef([]);

  useEffect(() => {
    if (window.location.hash === "#init" && serverId === "") {
      const id = uuid();
      setServerId(id);
      serverIdRef.current = id;
    }
  }, []);

  useEffect(() => {
    if (socketRef.current === null) {
      socketRef.current = io.connect("https://xsocial-agent.herokuapp.com/");
    }
  }, []);

  const initPosts = (data) => {
    setPosts(data);
    /**
     *  postsRef is a backup of posts, it will used in getAllPreviousPosts
     *  keep update postsRef when you update posts
     */
    postsRef.current = data;
  };

  const receiveDataHandler = useCallback((uint8arrdata) => {
    const data = Decodeuint8arr(uint8arrdata);
    const parsedData = JSON.parse(data);
    if (parsedData.type === "gossip-post") {
      if (gossipPostSetRef.current.has(parsedData.id)) {
        console.log("already gossiped", parsedData.id);
        return;
      } else {
        console.log("receive data", parsedData);
        gossipPostSetRef.current.add(parsedData.id);
        addPost(parsedData);
        gossipPost(parsedData);
      }
    }

    if (parsedData.type === "getAllPreviousPosts") {
      const { senderId } = parsedData;
      const peer = peersRef.current.find(({ peerID }) => peerID === senderId);
      if (peer) {
        const data = {
          type: "allPreviousPosts",
          senderId: socketRef.current.id,
          timeStamp: new Date().getTime(),
          data: postsRef.current,
        };
        const dataString = JSON.stringify(data);
        try {
          peer.peer.send(dataString);
        } catch (error) {
          console.log("allPreviousPosts error", peer.peerID);
        }
      } else {
        console.log("getAllPreviousPosts handler error", peer);
      }
    }

    if (parsedData.type === "allPreviousPosts") {
      const { data } = parsedData;
      initPosts(data);
      console.log("get All previous posts", data);
    }

    if (parsedData.type === "disconnect") {
      console.log("disconnect ", parsedData.senderId);
      const index = peersRef.current.findIndex(
        ({ peerID }) => peerID === parsedData.senderId
      );
      if (index !== -1) {
        peersRef.current.splice(index, 1);
        setPeers(peersRef.current);
      }
    }

    if (parsedData.type === "selectNextLeader") {
      console.log(parsedData.senderId, " is failed");
      console.log("selectNextLeader start----");
      const alivePeers = peersRef.current.filter(
        ({ peerID }) => peerID !== parsedData.senderId
      );
      const alivePeersBiggerThanMe = alivePeers.filter(
        ({ peerID }) => peerID > socketRef.current.id
      );
      console.log(
        "selectNextLeader alivePeersBiggerThanMe ----",
        alivePeersBiggerThanMe
      );
      if (alivePeersBiggerThanMe.length === 0 || parsedData.isLastOne) {
        declareLeader(alivePeers, socketRef.current.id);
      } else {
        bullyElectionCountRef.current = alivePeersBiggerThanMe.length;
        console.log("selectNextLeader bully start----");
        alivePeersBiggerThanMe.forEach(({ peer }) => {
          const data = JSON.stringify({
            type: "bullyElectionReq",
            senderId: socketRef.current.id,
            timeStamp: new Date().getTime(),
          });
          peer.send(data);
        });

        setTimeout(() => {
          if (bullyElectionCountRef.current === 0) {
            console.log("I am not leader");
          } else {
            declareLeader(alivePeers, socketRef.current.id);
          }
        }, BULLY_TIMEOUT);
      }
    }

    if (parsedData.type === "bullyElectionReq") {
      if (parsedData.senderId > socketRef.current.id) {
        const data = JSON.stringify({
          type: "bullyElectionAck",
          senderId: socketRef.current.id,
          timeStamp: new Date().getTime(),
        });
        const index = peersRef.current.findIndex(
          ({ peerID }) => peerID === socketRef.current.id
        );
        try {
          if (index !== -1) {
            peersRef.current[index].peer.send(data);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        // no ack, continue bully
        const alivePeersBiggerThanMe = peersRef.current.filter(
          ({ peerID }) => peerID > socketRef.current.id
        );
        if (alivePeersBiggerThanMe.length === 0) {
          declareLeader(peersRef.current, socketRef.current.id);
        } else {
          bullyElectionCountRef.current = alivePeersBiggerThanMe.length;

          alivePeersBiggerThanMe.forEach(({ peer }) => {
            const data = JSON.stringify({
              type: "bullyElectionReq",
              senderId: socketRef.current.id,
              timeStamp: new Date().getTime(),
            });
            peer.send(data);
          });

          setTimeout(() => {
            if (bullyElectionCountRef.current === 0) {
              console.log("I am not leader");
            } else {
              declareLeader(peersRef.current, socketRef.current.id);
            }
          }, BULLY_TIMEOUT);
        }
      }
    }

    if (parsedData.type === "bullyElectionAck") {
      bullyElectionCountRef.current = bullyElectionCountRef.current - 1;
    }

    if (parsedData.type === "declareLeader") {
      leaderRef.current = parsedData.senderId;
      console.log(parsedData.senderId, " is the new leader");
    }
    if (parsedData.type === "snapshotReq") {
      const localSnapshot = getLocalSnapshot();
      const reqPeer = peersRef.current.find(
        ({ peerID }) => peerID === parsedData.senderId
      );
      console.log("send snapshotAck to:", reqPeer.peerID);
      if (reqPeer) {
        const data = JSON.stringify({
          type: "snapshotAck",
          senderId: socketRef.current.id,
          timeStamp: new Date().getTime(),
          snapshotTimeStamp: parsedData.snapshotTimeStamp,
          data: localSnapshot,
        });
        try {
          reqPeer.peer.send(data);
        } catch (error) {
          console.log("send snapshotAck to: error", error, reqPeer.peerID);
        }
      }
    }
    if (parsedData.type === "snapshotAck") {
      console.log("received snapshotAck from:", parsedData.senderId);
      saveSnapshotToLocal(parsedData.snapshotTimeStamp, parsedData.data);
    }
  }, []);

  const declareLeader = (peers, myId) => {
    console.log("declareLeader start----");
    const data = JSON.stringify({
      type: "declareLeader",
      senderId: myId,
    });
    peers.forEach(({ peer }) => {
      try {
        peer.send(data);
      } catch (error) {
        console.log(error);
      }
    });
    socketRef.current.emit("declareLeader");
    console.log("declareLeader end----");
  };

  const createPeer = useCallback(
    (userToSignal, callerID) => {
      const peer = new Peer({
        initiator: true,
        trickle: false,
      });

      peer.on("signal", (signal) => {
        socketRef.current.emit("sending signal", {
          userToSignal,
          callerID,
          signal,
        });
      });

      peer.on("data", receiveDataHandler);

      peer.on("error", (err) => {
        if (err.code === "ERR_CONNECTION_FAILURE") {
          console.log("err.code", err.code);
        }
        console.log("error", err, "id: ", callerID);
      });

      return peer;
    },
    [receiveDataHandler]
  );

  const addPeer = useCallback(
    (incomingSignal, callerID) => {
      const peer = new Peer({
        initiator: false,
        trickle: false,
      });
      peer.on("signal", (signal) => {
        socketRef.current.emit("returning signal", { signal, callerID });
      });
      peer.on("data", receiveDataHandler);

      peer.on("error", (err) => {
        if (err.code === "ERR_CONNECTION_FAILURE") {
          console.log("err.code", err.code);
        }
        console.log("error", err, "id: ", callerID);
      });

      peer.signal(incomingSignal);
      return peer;
    },
    [receiveDataHandler]
  );

  const getAllPreviousPosts = useCallback(() => {
    const leaderPeer = peersRef.current.find(({ leader }) => leader);
    if (leaderPeer) {
      const data = {
        type: "getAllPreviousPosts",
        senderId: socketRef.current.id,
      };
      const dataString = JSON.stringify(data);
      try {
        leaderPeer.peer.send(dataString);
      } catch (error) {
        console.log("getAllPreviousPosts error", leaderPeer.peerID);
      }
      console.log("getAllPreviousPosts request send");
    } else {
      console.log("getAllPreviousPosts request failed");
    }
  }, []);

  const connect = useCallback(
    (groupId) => {
      socketRef.current.emit("join room", groupId);
      socketRef.current.on("all users", (payload) => {
        const { usersInThisRoom: users, leader } = payload;
        console.log("show leader", leader);
        leaderRef.current = leader;

        const _peers = [];

        users.forEach((userID) => {
          const peer = createPeer(userID, socketRef.current.id);
          peersRef.current.push({
            peerID: userID,
            peer,
            leader: userID === leader,
          });
          _peers.push({ peerID: userID, peer });
        });

        console.log("show peersRef", peersRef.current);
        setPeers(_peers);
        /**
         * this timeout is to wait for the establishing of peer to peer connection
         */
        setTimeout(() => getAllPreviousPosts(), 4000);
      });

      socketRef.current.on("user joined", (payload) => {
        const peer = addPeer(payload.signal, payload.callerID);
        peersRef.current.push({
          peerID: payload.callerID,
          peer,
        });
        setPeers((users) => [...users, { peerID: payload.callerID, peer }]);
      });

      socketRef.current.on("receiving returned signal", (payload) => {
        const item = peersRef.current.find((p) => p.peerID === payload.id);
        item.peer.signal(payload.signal);
      });

      socketRef.current.on("room full", (payload) => {
        console.log("room full");
      });
    },
    [addPeer, createPeer, getAllPreviousPosts]
  );

  const disconnect = useCallback(() => {
    console.log("disconnect start----");

    socketRef.current.emit("destroy");

    peersRef.current.forEach(({ peerID, peer }) => {
      const data = {
        type: "disconnect",
        senderId: socketRef.current.id,
        timeStamp: new Date().getTime(),
      };
      const dataString = JSON.stringify(data);
      try {
        peer.send(dataString);
      } catch (error) {
        console.log("disconnect send error", error);
      }
    });

    /**
     * if client is leader, tell next peer to start leader election
     */
    if (socketRef.current.id === leaderRef.current) {
      console.log("selectNextLeader begin------ is leader");
      setTimeout(() => {
        const data = {
          type: "selectNextLeader",
          senderId: socketRef.current.id,
          timeStamp: new Date().getTime(),
          isLastOne: peersRef.current.length === 1,
        };
        const dataString = JSON.stringify(data);
        if (peersRef.current && peersRef.current.length > 0) {
          peersRef.current[0].peer.send(dataString);
          console.log("send selectNextLeader to ", peersRef.current[0].peerID);
        }
      }, 2000);
    }

    setTimeout(() => {
      peersRef.current.forEach(({ peerID, peer }) => {
        console.log(peerID, "connection is destroyed");
        peer.destroy();
      });
      peersRef.current = [];
      setPeers([]);
    }, 30000);
  }, []);

  const addPost = (data) => {
    setPosts((pre) => [...pre, data]);
    postsRef.current = [...postsRef.current, data];
  };

  const gossipPost = (data) => {
    const dataString = JSON.stringify(data);
    const gossipTargetPeers = chooseRandom(
      peersRef.current,
      GOSSIP_PEER_NUMBER
    );
    gossipTargetPeers.forEach(({ peerID, peer }) => {
      try {
        console.log("gossip peer send", peer);
        peer.send(dataString);
      } catch (error) {
        console.log("gossip failed", peerID);
      }
    });
  };

  const connectBtnHandler = useCallback(() => {
    if (serverId === "") {
      alert("Please input group id");
      return;
    }
    if (socketRef.current === null) {
      alert("socket io not init");
      return;
    }
    if (isConnect) {
      disconnect();
      setIsConnect(false);
    } else {
      connect(serverId);
      setIsConnect(true);
    }
  }, [connect, disconnect, isConnect, serverId]);

  const serverIdOnchange = (e) => {
    e.preventDefault();
    setServerId(e.target.value);
    serverIdRef.current = e.target.value;
  };

  useEffect(() => {
    console.log("show current", peers);
  }, [peers]);

  const getLocalSnapshot = () => {
    const snapshot = {
      id: socketRef.current.id,
      timeStamp: new Date().getTime(),
      peers: peersRef.current,
      posts: postsRef.current,
      leader: leaderRef.current,
      isDoingLeaderElection: bullyElectionCountRef.current !== 0,
      bullyCount: bullyElectionCountRef.current,
    };
    return snapshot;
  };

  const requestOtherPeerSnapshot = (snapshotTimeStamp) => {
    console.log("send snapshotTimeStamp");
    const data = JSON.stringify({
      type: "snapshotReq",
      senderId: socketRef.current.id,
      timeStamp: new Date().getTime(),
      snapshotTimeStamp: snapshotTimeStamp,
    });
    peersRef.current.forEach(({ peer }) => {
      try {
        peer.send(data);
      } catch (error) {
        console.log("send snapshotTimeStamp error", error);
      }
    });
  };

  const snapshotHandler = () => {
    const timeStamp = new Date().getTime();
    const localSnapshot = getLocalSnapshot();
    saveSnapshotToLocal(timeStamp, localSnapshot);
    requestOtherPeerSnapshot(timeStamp);
    console.log("start snapshot------", timeStamp);
  };

  return (
    <div className="App">
      <header className="App-header h-32 bg-sky-600 text-white sticky top-0">
        <h1 className="text-3xl font-bold ">X-Social</h1>
        <h3 className="text-sm ">Decentralized Social Network</h3>
        <div className="inline-flex mt-4">
          <input
            type="text"
            className="h-6 w-32 mr-2 text-sm text-black"
            value={serverId}
            onChange={serverIdOnchange}
          />
          <button
            className="h-6 w-12 text-sm hover:text-black"
            onClick={connectBtnHandler}
          >
            {isConnect ? "disconnect" : "connect"}
          </button>
        </div>
      </header>
      <div className="App-body">
        <Form
          peersRef={peersRef}
          socketRef={socketRef}
          gossipPostSetRef={gossipPostSetRef}
          addMyPostMessage={addPost}
        />
        {posts.map(({ senderId, timeStamp, data, id }) => (
          <Post
            key={id + "/post"}
            name={senderId}
            time={timeStamp}
            data={data}
          />
        ))}
      </div>
      <footer className="App-footer w-full h-16 bg-sky-600 static bottom-0 text-white ">
        <div> onlinePeers: {peers.length}</div>
        <div>
          <button onClick={snapshotHandler}>snapshot</button>
        </div>
      </footer>
    </div>
  );
}

export default App;
